export const DELETE_AUTH_INFO = 'DELETE_AUTH_INFO';
export const STORE_USER = 'STORE_USER';
export const STORE_USER_SAGA = 'STORE_USER_SAGA';
export const SET_OCCURRENCES_WITH_USERS = 'SET_OCCURRENCES_WITH_USERS';

//userAccount
export const STORE_PROFILE_IMG_URL = 'STORE_PROFILE_IMG_URL';
export const STORE_PROFILE_IMG_URL_SAGA = 'STORE_PROFILE_IMG_URL_SAGA';
export const SET_ADMIN = 'SET_ADMIN';
export const GET_ADMIN = 'GET_ADMIN';

//events
export const SET_OCCURRENCES = 'SET_OCCURRENCES';
export const FETCH_OCCURRENCES = 'FETCH_OCCURRENCES';
export const PROCESS_EVENT_ATTENDANCE = 'PROCESS_EVENT_ATTENDANCE';
export const SET_EVENT = 'SET_EVENT';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const PROCESS_EVENT = 'PROCESS_EVENT';
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const SET_LOADING_EVENT = 'SET_LOADING_EVENT';
export const FETCH_USERS_PROFILES = 'FETCH_USERS_PROFILES';
export const SET_USERS_PROFILES = 'SET_USERS_IDS';
export const SET_FUTURE_OCCURRENCES = 'SET_FUTURE_OCCURRENCES';

// Event News
export const FETCH_NEWS_SAGA = 'FETCH_NEWS_SAGA';
export const SAVE_NEWS_SAGA = 'SAVE_NEWS_SAGA';
export const STORE_EVENT_NEWS = 'STORE_EVENT_NEWS';
export const DELETE_EVENT_SAGA = 'DELETE_EVENT_SAGA';

//authentication
export const SET_AUTH_INFO = 'SET_AUTH_INFO';
export const LOG_USER_OUT = 'LOG_USER_OUT';
export const UNSET_LOGGED_USER = 'UNSET_LOGGED_USER';
export const LOGIN_USER = 'LOGIN_USER';

//manageToken
export const SET_TOKEN_STATUS = 'SET_TOKEN_STATUS';
export const GET_NEW_TOKEN_SAGA = 'GET_NEW_TOKEN_SAGA';

//ADD Player
export const CREATE_PLAYER_SAGA = 'CREATE_PLAYER_SAGA';

//Players Administration
export const FETCH_PLAYERS_SAGA = 'FETCH_PLAYERS_SAGA';
export const SET_PLAYERS = 'SET_PLAYERS';
export const SAVE_EDITED_PLAYER_SAGA = 'SAVE_EDITED_PLAYER_SAGA';
